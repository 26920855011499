import BaseMeta, { MetaProps } from '@amf/shared/components/Meta';
import Head from 'next/head';
import { useTranslate } from '@tolgee/react';

type Props = Omit<MetaProps, 'title' | 'description'> & {
  title?: string;
  description?: string;
};

function Meta({ title, description, ...props }: Props) {
  const { t } = useTranslate('superliga');
  const seoTitle = `${title ? `${title} • ` : ''}${t('seo.title')}`;
  const seoDescription = description ?? (t('seo.description') as string);
  return (
    <>
      <Head>
        <link rel='icon' type='image/svg+xml' href='/favicon.svg' />
        <link rel='icon' type='image/png' href='/favicon.png' />
        <meta name='theme-color' content='#FFC81A' />
      </Head>
      <BaseMeta
        title={seoTitle}
        description={seoDescription}
        cookieKey={process.env.NEXT_PUBLIC_COOKIE_SCRIPT_KEY}
        {...props}
      />
    </>
  );
}

export default Meta;
